import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITipiContratti {

    getUrlDomain(nome_metodo) {

        let result = "";

        if (location.hostname == "localhost") {
            result = location.protocol + "//emtool.local/";
        }

        if (location.hostname != "localhost") {

            result = location.protocol + "//" + location.hostname + "/";

        }

        return result + nome_metodo;


    }


    async initInsert(user) {

        let formData = new FormData();

        formData.append("username", user);

        return axios.post(this.getUrlDomain("TwoRecruitRegioniInitInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async insertTipoContratto(tipo_contratto, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in tipo_contratto) {
            formData.append(key, tipo_contratto[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitContractTypeInsApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goInsertTipoContratto(tipo_contratto, user) {

        let formData = new FormData();

        formData.append("username", user);

        for (var key in tipo_contratto) {
            formData.append(key, tipo_contratto[key]);
        }

        return axios.post(this.getUrlDomain("goInsertTipoContratto2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }



    async initEdit(id, user, prodotto) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);
        formData.append("prodotto", prodotto);


        return axios.post(this.getUrlDomain("TwoRecruitContractTypeInitEditApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }


    async updateTipoContratto(tipo_contratto, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in tipo_contratto) {
            formData.append(key, tipo_contratto[key]);
        }

        return axios.post(this.getUrlDomain("TwoRecruitContractTypeUpdApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goUpdateTipoContratto(tipo_contratto, user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);

        for (var key in tipo_contratto) {
            formData.append(key, tipo_contratto[key]);
        }

        return axios.post(this.getUrlDomain("goUpdateTipoContratto2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async deleteTipoContratto(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("TwoRecruitContractTypeDelApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async goDeleteTipoContratto(user, id) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("id", id);


        return axios.post(this.getUrlDomain("goDeleteTipoContratto2Recruit"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async initFilter(user) {

        let formData = new FormData();

        formData.append("username", user);


        return axios.post(this.getUrlDomain("TwoRecruitContractTypeInitFilterApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }

    async checkCode(user, code) {

        let formData = new FormData();

        formData.append("username", user);
        formData.append("code", code);


        return axios.post(this.getUrlDomain("TwoRecruitContractTypeVerCodeApi"), formData, { 'Content-Type': 'multipart/form-data' });

    }




}

export default new APITipiContratti({
    url: "https://services.ebadge.it/public/api/"
})
